<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="createNewTransfer"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Agent Transfer</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :search="false"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getTransfers"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
    <AgentTransferForm
      v-if="showModal"
      @close="closeModal"
      @onSaved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import AgentTransferForm from './AgentTransferForm.vue';

export default {
  components: {
    VueTable,
    AgentTransferForm,
  },
  data() {
    return {
      filters: {
        paginate: true,
      },
      vTable: {
        headers: [
          {
            title: 'commission.description',
            mask: 'New Commission',
            sortable: true,
          },
          {
            title: 'oldAgent.first_name',
            mask: 'Old agent',
            sortable: true,
          },
          {
            title: 'newAgent.first_name',
            mask: 'New agent',
            sortable: true,
          },
          {
            title: 'programmes_id',
            mask: 'Transfered programmes',
            sortable: true,
            callback: (item) => item.length,
          },
          {
            title: 'created_at',
            mask: 'Date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'creator.first_name',
            mask: 'Responsible User',
            sortable: true,
          },
        ],
        values: {},
      },
      showModal: false,
      newTransfer: null,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getTransfers(params) {
      this.request(this.$API.AGENT_TRANSFER_TRANSACTIONS, 'get', { ...params, ...this.filters }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    createNewTransfer() {
      this.showModal = true;
      this.newTransfer = null;
    },
    closeModal() {
      this.showModal = false;
      this.newTransfer = null;
    },
  },
};
</script>
