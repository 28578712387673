<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      New Agent Transfer Form
    </template>
    <template slot="body">
      <ValidationObserver ref="form">
        <AgentsSelect
          v-model="form.old_agent_id"
          label="Old Agent"
          rules="required"
        />

        <AgentsSelect
          v-model="form.new_agent_id"
          label="New Agent"
          rules="required"
          @change="selectNewAgent"
        />

        <CommissionsSelect
          v-show="!!selectedNewAgent"
          v-model="form.new_agent_commission_id"
          rules="required"
          :agent="selectedNewAgent"
        />
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        class="md-success pull-right"
        @click="saveTransfer"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { AgentsSelect, CommissionsSelect } from '@/components/Inputs/selects';

extend('required', required);

export default {
  components: {
    AgentsSelect,
    CommissionsSelect,
  },
  data: () => ({
    form: {
      old_agent_id: null,
      new_agent_id: null,
      new_agent_commission_id: null,
    },
    selectedNewAgent: null,
  }),
  methods: {
    selectNewAgent(newAgent) {
      this.selectedNewAgent = newAgent;
    },
    close() {
      this.$refs.modal.close();
    },
    saveTransfer() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.request(`${this.$API.AGENT_TRANSFER_TRANSACTIONS}`, 'post', { ...this.form }, () => {
            this.fireSuccess('Transfered successfuly');
            this.$emit('onSaved');
            this.close();
          });
        } else {
          this.fireError('Please, complete all the required fields before.');
        }
      });
    },
  },
};
</script>
